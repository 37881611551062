import * as Sentry from '@sentry/solid'

import { clientEnv } from '#/clientEnv'

if (clientEnv.NODE_ENV === 'production' || clientEnv.ENABLE_SENTRY === '1') {
  Sentry.init({
    dsn: 'https://4308e73510079a84f2ebbacb40d86c1a@o4508874828087296.ingest.de.sentry.io/4508874833461328',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: clientEnv.STACK_NAME,
  })
}
